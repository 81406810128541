<template>
  <div v-if="dateRange" class="generate-youth-home-stats">
    <div v-if="generateMode">
      <b-row>
        <b-col cols="4">
          <b-form-group
            id="start-date-group"
            label="Date de début:"
            label-for="start-date"
            description=""
          >
            <b-form-input
              id="start-date"
              v-model="startGenerateDate"
              type="date"
              :disabled="generateInProgress"
              :min="minDate"
              :max="maxDate"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            id="end-date-group"
            label="Date de fin:"
            label-for="end-date"
            description=""
          >
            <b-form-input
              id="end-date"
              v-model="endGenerateDate"
              type="date"
              :disabled="generateInProgress"
              :min="minDate"
              :max="maxDate"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="warning-text" style="margin: 10px 0;" v-if="willOverwriteData">
            <i class="fa fa-warning"></i>
            Des données existent du {{ startDataDate | dateToString }}
            au {{ endDataDate | dateToString }}.
            Elles seront supprimées et remplacées.
          </div>
          <div class="error-text" style="margin: 10px 0;" v-if="!isValid">
            <i class="fa fa-warning"></i> Les dates ne sont pas valides
          </div>
          <div class="error-text" style="margin: 10px 0;" v-if="hasGaps">
            <i class="fa fa-warning"></i> Les dates peuvent créer des trous dans les données
          </div>
        </b-col>
      </b-row>
      <div class="action-block">
        <b-row>
          <b-col cols="8">
            <div v-if="canLock">
              <b-form-checkbox v-model="lockData" id="lockData" :disabled="generateInProgress">
                <i class="fa fa-lock"></i> Figer définitivement les données
              </b-form-checkbox>
              <div v-if="lockData" class="warning-text">
                Les inscriptions de la période ne pourront plus être modifiées.
                Les données CAF ne pourront plus être générées à nouveau.
              </div>
            </div>
          </b-col>
          <b-col cols="2">
            <div class="generate-form-button">
              <a class="btn btn-secondary btn-block"
                 @click.prevent="cancelGeneration()"
                 :class="{ disabled: generateCancelled, }"
              >
                <span v-if="generateCancelled">Annulation</span>
                <span v-else>Annuler</span>
              </a>
            </div>
          </b-col>
          <b-col cols="2">
            <div class="generate-form-button">
              <a class="btn btn-block" v-if="!isLocked"
                 @click.prevent="doGenerate()"
                 :class="{ disabled: generateInProgress || !isValid || hasGaps, 'btn-primary': !lockData, 'btn-danger': lockData }"
              >
                <span v-if="lockData">Générer et figer</span>
                <span v-else>Générer</span>
              </a>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col cols="10">
          <b-progress :max="nbDays" height="2rem" v-if="generateInProgress">
            <b-progress-bar :value="daysDone">
            </b-progress-bar>
          </b-progress>
        </b-col>
        <b-col cols="2" class="text-right">
          <b><span :title="progressDetail">{{ progressPercentage }}</span></b>
        </b-col>
      </b-row>
    </div>
    <div v-if="!generateMode">
      <b-row>
        <b-col cols="10">
          <div>
            <div v-if="startDataDate">
              Des données existent du {{ startDataDate | dateToString }}
              au {{ endDataDate | dateToString }}
            </div>
            <div v-else>
              Aucune donnée existante
            </div>
          </div>
        </b-col>
        <b-col cols="2">
          <a
            v-if="!generateMode && !isLocked"
            class="btn btn-secondary btn-block" @click.prevent="enterGenerateMode()"
          >
            Générer
          </a>
        </b-col>
      </b-row>
      <div v-if="isLocked">
        <div class="locked-data">
          <i class="fa fa-lock"></i> Les données ont été figées et ne peuvent plus être
          générées
        </div>
      </div>
      <div class="generation-history" v-if="dataGeneration.length">
        <div v-for="generation in dataGeneration" :key="generation.id">
          Données {{ generation.dataName() }}
          générées le {{ generation.getGeneratedOn() }}
          par {{ generation.generatedBy }}
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeReportDataGeneration } from '@/types/reports'

export default {
  name: 'GenerateYouthHomeStats',
  mixins: [BackendMixin],
  props: {
    startDate: String,
    endDate: String,
  },
  data() {
    return {
      minDate: '',
      maxDate: '',
      generateMode: false,
      generateInProgress: false,
      generateCancelled: false,
      startGenerateDate: '',
      endGenerateDate: '',
      startDataDate: '',
      endDataDate: '',
      nbDays: 0,
      daysDone: 0,
      dataGeneration: [],
      isLocked: false,
      lockData: false,
    }
  },
  watch: {
    startDate: function() { this.init() },
    endDate: function() { this.init() },
    generateMode: function() { this.$emit('generateMode', { mode: this.generateMode, }) },
  },
  computed: {
    dateRange() {
      if (this.startDate && this.endDate) {
        const startString = moment(this.startDate).format('DD/MM/YYYY')
        const endString = moment(this.endDate).format('DD/MM/YYYY')
        return 'Du ' + startString + ' au ' + endString
      }
      return ''
    },
    dateRangeValue() {
      if (this.startDate && this.endDate) {
        const startString = moment(this.startDate).format('YYYY-MM-DD')
        const endString = moment(this.endDate).format('YYYY-MM-DD')
        return '' + startString + '/' + endString
      }
      return ''
    },
    willOverwriteData() {
      if (this.startGenerateDate && this.endGenerateDate && this.startDataDate) {
        const startDate = moment(this.startGenerateDate)
        const endDate = moment(this.endGenerateDate)
        const startLimit = moment(this.startDataDate)
        const endLimit = moment(this.endDataDate)
        return !((endLimit < startDate) || (startLimit > endDate))
      }
      return false
    },
    isValid() {
      if (this.startGenerateDate && this.endGenerateDate) {
        const startDate = moment(this.startGenerateDate)
        const endDate = moment(this.endGenerateDate)
        return endDate.diff(startDate) >= 0
      }
      return false
    },
    hasGaps() {
      const startDate = moment(this.startGenerateDate)
      const endDate = moment(this.endGenerateDate)
      const limStartDate = moment(this.startDataDate)
      const limEndDate = moment(this.endDataDate).add(1, 'day')

      // Il faut éviter les trous:
      // Si une date de fin de génération est supérieure à la date de fin actuelle
      // alors la date de début de génération est au moins antérieure à la date de fin actuelle
      if ((endDate > limEndDate) && (startDate > limEndDate)) {
        return true
      }

      // Si une date de début de génération est inférieure à la date de début actuelle
      // alors la date de fin de génération est au moins postérieure à la date de fin actuelle.
      if ((startDate < limStartDate) && (endDate < limStartDate)) {
        return true
      }

      return false
    },
    progressPercentage() {
      if (this.nbDays) {
        const value = Math.round(100 * this.daysDone / this.nbDays)
        return '' + value + '%'
      }
      return ''
    },
    progressDetail() {
      return '' + this.daysDone + '/' + this.nbDays
    },
    canLock() {
      if (this.endDataDate) {
        if (moment(this.endDataDate).year() < moment().year()) {
          // si la date de fin est sur une année précédente
          return true
        }
      }
      return false
    },
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions(['addError', 'addWarning']),
    async init() {
      await this.loadLimits()
      await this.loadGenerationHistory()
    },
    enterGenerateMode() {
      this.generateMode = true
      this.startGenerateDate = this.minDate
      this.endGenerateDate = this.maxDate
    },
    async loadLimits() {
      if (this.dateRangeValue) {
        const url = '/reports/api/inscription-archives/' + this.dateRangeValue + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.startDataDate = resp.data['min_date'] || ''
          this.endDataDate = resp.data['max_date'] || ''
          this.minDate = moment(this.startDate).format('YYYY-MM-DD')
          this.maxDate = moment(this.endDate).format('YYYY-MM-DD')
          if (this.endDataDate) {
            this.startGenerateDate = moment(this.endDataDate).add(1, 'day').format('YYYY-MM-DD')
          } else {
            this.startGenerateDate = this.minDate
          }
          this.endGenerateDate = this.maxDate
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadGenerationHistory() {
      this.dataGeneration = []
      if (this.dateRangeValue) {
        const isValid = this.startDataDate && this.endDataDate
        if (isValid) {
          let url = '/reports/api/reports-data-generation/'
          url += '?start_date=' + moment(this.startDataDate).format('YYYY-MM-DD')
          url += '&end_date=' + moment(this.endDataDate).format('YYYY-MM-DD')
          const backendApi = new BackendApi('get', url)
          try {
            const resp = await backendApi.callApi()
            this.dataGeneration = resp.data['generations'].map(makeReportDataGeneration)
            this.isLocked = !!resp.data['is_locked']
          } catch (err) {
            await this.addError(this.getErrorText(err))
          }
        }
      }
    },
    cancelGeneration() {
      if (this.generateInProgress) {
        this.generateCancelled = true
      } else {
        this.generateMode = false
      }
    },
    async doGenerate() {
      this.generateInProgress = true
      this.generateCancelled = false
      this.$emit('generating')
      this.daysDone = 0
      const generatedOn = moment().format('YYYY-MM-DD HH:mm:ss')
      this.nbDays = moment(this.endGenerateDate).diff(moment(this.startGenerateDate), 'days') + 1
      if (this.dateRangeValue) {
        const url = '/reports/api/inscription-archives/' + this.dateRangeValue + '/'
        const backendApi = new BackendApi('post', url)
        let currentDate = moment(this.startGenerateDate)
        while (this.daysDone < this.nbDays) {
          try {
            let data = {
              date: currentDate.format('YYYY-MM-DD'),
              generated_on: generatedOn,
            }
            if (this.canLock && this.lockData) {
              data.lock = true
            }
            await backendApi.callApi(data)
            currentDate = currentDate.add(1, 'day')
            this.daysDone += 1
            if (this.generateCancelled) {
              await this.addWarning('La génération a été interrompue')
              break
            }
          } catch (err) {
            await this.addError(this.getErrorText(err))
            break
          }
        }
      }
      await this.init()
      this.nbDays = 0
      this.daysDone = 0
      this.generateInProgress = false
      this.generateMode = false
      this.generateCancelled = false
      this.$emit('generated')
    },
  },
}
</script>
<style scoped lang="less">
.generate-youth-home-stats {
  background: #e0e0e0;
  color: #222;
  padding: 10px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 20px;
}
.generation-history {
  padding: 10px;
  background: #eee;
  font-size: 12px;
}
.date-range-info {
  padding: 10px;
  border: solid 1px #888;
  margin: 5px 0;
}
.date-range-warning {
  padding: 5px;
  background: #ffc600;
}
.locked-data {
  padding: 2px;
  background: #ffc600;
}
.action-block {
  padding: 5px;
  background: #ccc;
}
.dark-mode {
  .generate-youth-home-stats {
    background: #444;
    color: #fff;
  }
}
</style>
